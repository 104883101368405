<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-col>
            <h3 class="grey--text">
              <v-icon class="mr-2" large>mdi-account-hard-hat-outline</v-icon
              >Service Rendered
            </h3></v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="2">
            <addUnit
              :customer_id="customer_id"
              v-if="show_add_unit"
              @close="close('unit')"
          /></v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-data-table
          dense
          v-if="ready"
          :loading="!ready"
          label="Services"
          :items="services"
          :headers="headers"
        >
        </v-data-table>
        <h2 class="text-center" v-else>Data Table is loading..</h2>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      show_problem_modal: false,
      selected_item: {},
      show_add_unit: false,
      show_update_modal: false,
      dialog: true,
      ready: true,
      headers: [
        { text: "id", value: "id" },
        { text: "Unit Type", value: "unit_type" },
        { text: "Unit Number", value: "unit_number" },
        { text: "Brand", value: "brand" },
        { text: "Unit Capacity", value: "unit_capacity" },
        { text: "Unit Orientation", value: "unit_orientation" },
        { text: "Unit Power Supply", value: "unit_power_supply" },
        { text: "Ampheres", value: "ampheres" },
        { text: "Refrigerant", value: "refrigerant" },
        { text: "Patch Number", value: "patch_number" },
        { text: "Serial Number", value: "serial_number" },
      ],
    };
  },
  props: ["services"],
  methods: {
    async del_unit(item) {
      this.loading = true;
      await this.delete_unit(item);
      this.loading = false;
      alert("Successfully deleted a unit");
    },
    open_modal(request) {
      this.selected_item = request[0];
      if (request[1] == "problem") this.show_problem_modal = true;
      if (request[1] == "update") this.show_update_modal = true;
    },
    close(type) {
      if (type == "customer") return (this.show_edit_profile = false);
      if (type == "unit") return (this.show_add_unit = false);
      if (type == "add_invoice") return (this.show_add_invoice = false);
      if (type == "invoice_card") return (this.show_invoice_card = false);
      if (type == "unit") return (this.show_unit = false);
      if (type == "update_modal") return (this.show_update_modal = false);
      if (type == "problem_modal") return (this.show_problem_modal = false);
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped></style>
